export interface StudentModel {
  id: number;
  name: string;
  image: string;
  token: string;
  biography: string;
  pass: string;
  responsibleName: string;
  responsibleEmail: string;
  schoolName: string;
  teacher?: Teacher;
  pages?: StudentPage[];
}

interface Teacher {
  email: number;
  name: string;
  userId: string | null;
}

export interface StudentPage {
  id: number;
  value: PageValue;
  position: number;
  name: string;
  done: boolean;
  schema: PageSchemaEnum;
}

type PageValue = {
  text: string | null;
  image_hash: string | null;
};

export interface UpdateBiographyStudentPayload {
  image?: File;
  biography?: string;
}

export enum PageSchemaEnum {
  COVER = 1,
  BIOGRAPHY = 2,
  TEXT = 3,
  IMAGE = 4
}
