import { UpdateBiographyStudentPayload } from './student.model';
import { StudentDataParams } from '../../services/cycles.service';

export class LoadStudentByTokenAction {
  static readonly type = '[Student] Load student by token';
  constructor(public payload: string) {}
}

export class LoadStudentByClassAndGradeAction {
  static readonly type = '[Student] Load student by class and grade';
  constructor(public payload: StudentDataParams) {}
}

export class UpdateStudentBiographyAction {
  static readonly type = '[Student] Update biography student';
  constructor(public payload: UpdateBiographyStudentPayload) {}
}
