import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LivSuccessResponse } from 'src/app/core/models/liv-response-protocol.model';

import { ConfigService } from './config.service';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any | null;
}

interface IRequestUploadOptions extends IRequestOptions {
  method?: 'post' | 'put' | 'patch';
}

@Injectable({
  providedIn: 'root'
})
export class ApiLiv {
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {}

  get apiPortalUrl(): string {
    return this.configService.config?.apiPortalUrl ?? '';
  }

  get<T>(endpoint: string, options: IRequestOptions = {}): Observable<T> {
    const url = encodeURI(this.service + endpoint);
    return this.httpClient
      .get<LivSuccessResponse<T>>(url, options)
      .pipe(map((res) => res.data));
  }

  upload<T>(
    endpoint: string,
    data: FormData,
    options: IRequestUploadOptions = {}
  ): Observable<HttpEvent<T>> {
    const timeout = 30 * 60 * 1000; // 30 minutes
    const headers = new HttpHeaders().append('timeout', String(timeout));

    const { method = 'post', ...externalOptions } = options;

    const _options: IRequestOptions = {
      headers,
      ...externalOptions
    };

    const url = encodeURI(this.service + endpoint);
    return this.httpClient.request<T>(method, url, {
      ..._options,
      reportProgress: true,
      observe: 'events',
      body: data
    });
  }

  download<T = File>(
    endpoint: string,
    options: IRequestOptions = {}
  ): Observable<T> {
    const timeout = 15 * 60 * 1000; // 15 minutes;
    const headers = new HttpHeaders().append('timeout', String(timeout));

    const _options: IRequestOptions = {
      headers,
      ...options
    };

    const url = encodeURI(this.service + endpoint);
    return this.httpClient.get<T>(url, {
      ..._options,
      responseType: 'blob' as 'json'
    });
  }

  post<T>(
    endpoint: string,
    body: unknown,
    options: IRequestOptions = {}
  ): Observable<T> {
    const url = encodeURI(this.service + endpoint);
    return this.httpClient
      .post<LivSuccessResponse<T>>(url, body, options)
      .pipe(map((res) => res.data));
  }

  put<T>(
    endpoint: string,
    body: unknown,
    options: IRequestOptions = {}
  ): Observable<T> {
    const url = encodeURI(this.service + endpoint);
    return this.httpClient
      .put<LivSuccessResponse<T>>(url, body, options)
      .pipe(map((res) => res.data));
  }

  patch<T>(
    endpoint: string,
    body: unknown,
    options: IRequestOptions = {}
  ): Observable<T> {
    const url = encodeURI(this.service + endpoint);
    return this.httpClient
      .patch<LivSuccessResponse<T>>(url, body, options)
      .pipe(map((res) => res.data));
  }

  delete<T>(endpoint: string, options: IRequestOptions = {}): Observable<T> {
    const url = encodeURI(this.service + endpoint);
    return this.httpClient
      .delete<LivSuccessResponse<T>>(url, options)
      .pipe(map((res) => res.data));
  }

  graphql<T>(query: string, options: IRequestOptions = {}): Observable<T> {
    const url = encodeURI(this.service + '/graphql');
    return this.httpClient
      .post<LivSuccessResponse<T>>(url, JSON.stringify({ query }), options)
      .pipe(map((res) => res.data));
  }

  get service(): string {
    const url = this.apiPortalUrl.endsWith('/')
      ? this.apiPortalUrl.substring(0, this.apiPortalUrl.length - 1)
      : this.apiPortalUrl;

    return url;
  }
}
