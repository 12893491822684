import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiLiv } from 'src/app/core/services/api-liv.service';

import {
  StudentModel,
  UpdateBiographyStudentPayload
} from '../store/student/student.model';
import { StudentSchools } from '../store/student-schools/student-schools.model';

export interface StudentDataParams {
  studentIds: number[];
  schoolId: number;
  gradeId: number;
}

@Injectable({
  providedIn: 'root'
})
export class CyclesService {
  constructor(private apiLiv: ApiLiv) {}

  getStudent(studentToken: string) {
    return this.apiLiv.get<StudentModel>(`/Cycle/family/${studentToken}`);
  }

  getStudentDataByName(studentName: string) {
    const params = new HttpParams().set('studentName', studentName);
    return this.apiLiv.get<StudentSchools>(`/Cycle/family/name`, { params });
  }

  getStudentDataBySchoolAndGrade(studentParams: StudentDataParams) {
    let params = new HttpParams()
      .set('schoolId', studentParams.schoolId)
      .set('gradeId', studentParams.gradeId);

    studentParams.studentIds.forEach((id) => {
      params = params.append('studentIds', id);
    });

    return this.apiLiv.get<StudentModel>(`/Cycle/family/match`, { params });
  }

  updateStudentBiography(pageId: number, data: UpdateBiographyStudentPayload) {
    const formData = new FormData();

    if (data.biography !== undefined && data.biography !== null) {
      formData.append('text', data.biography);
    }

    if (data.image) {
      formData.append('image', data.image);
    }

    return this.apiLiv.post<void>(`/Cycle/pages/${pageId}/biography`, formData);
  }
}
