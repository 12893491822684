import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  StateToken,
  Store
} from '@ngxs/store';
import { EMPTY, catchError, take, tap } from 'rxjs';
import { LivErrorResponse } from 'src/app/core/models/liv-response-protocol.model';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoadingOverlayService } from 'src/app/shared/components/loading-overlay/loading-overlay.service';
import { LoadingStateEnum } from 'src/app/shared/enums/loading-state.enum';
import { RequestState } from 'src/app/shared/types/request-state';

import {
  LoadStudentByClassAndGradeAction,
  LoadStudentByTokenAction,
  UpdateStudentBiographyAction
} from './student.action';
import { PageSchemaEnum, StudentModel } from './student.model';
import { CyclesService } from '../../services/cycles.service';
import { SetStudentSessionAction } from '../student-session/student-session.action';
import { StudentSessionState } from '../student-session/student-session.state';

type Student = {
  id: number;
  name: string;
  image: string;
  pass: string;
  schoolName: string;
  pages: StudentModel['pages'];
  teacher: StudentModel['teacher'];
};

export interface StudentStateModel {
  student: Student | null;
  requestStatus: RequestState;
  updateStatus: RequestState;
}

const STUDENT_STATE_TOKEN = new StateToken<StudentStateModel>('student');

const initialState: Partial<StudentStateModel> = {
  student: null
};

@State<StudentStateModel>({
  name: STUDENT_STATE_TOKEN,
  defaults: {
    ...(initialState as StudentStateModel),
    requestStatus: {
      errors: [],
      status: LoadingStateEnum.idle
    },
    updateStatus: {
      key: '',
      errors: [],
      status: LoadingStateEnum.idle
    }
  }
})
@Injectable()
export class StudentState implements NgxsAfterBootstrap {
  constructor(
    private cyclesService: CyclesService,
    private toast: ToastService,
    private loader: LoadingOverlayService,
    private store: Store
  ) {}

  @Selector() static student({ student }: StudentStateModel) {
    return student ?? null;
  }

  @Selector() static studentTeacher({ student }: StudentStateModel) {
    return student?.teacher ?? null;
  }

  @Selector() static biography({ student }: StudentStateModel) {
    const biographyPage = student?.pages?.find(
      ({ schema }) => schema === PageSchemaEnum.BIOGRAPHY
    );

    return biographyPage?.value.text ?? '';
  }

  @Selector() static isLoading({ requestStatus }: StudentStateModel) {
    return requestStatus.status === LoadingStateEnum.loading;
  }

  @Selector() static requestStatus({ requestStatus }: StudentStateModel) {
    return requestStatus;
  }

  @Selector() static updateStatus({ updateStatus }: StudentStateModel) {
    return updateStatus;
  }

  ngxsAfterBootstrap(ctx: StateContext<StudentStateModel | null>) {
    const { pass } =
      this.store.selectSnapshot(StudentSessionState.student) ?? {};

    if (!pass) {
      return;
    }

    this.loader.open();

    ctx
      .dispatch(new LoadStudentByTokenAction(pass))
      .pipe(take(1))
      .subscribe(() => {
        this.loader.remove();
      });
  }

  @Action(LoadStudentByTokenAction) loadStudentByToken(
    { patchState, dispatch }: StateContext<StudentStateModel>,
    { payload }: LoadStudentByTokenAction
  ) {
    patchState({
      requestStatus: {
        errors: [],
        status: LoadingStateEnum.loading
      }
    });

    return this.cyclesService.getStudent(payload).pipe(
      catchError(() => {
        this.toast.error(
          'Não foi possível encontrar o aluno. Por favor, tente novamente.'
        );
        patchState({
          ...initialState,
          requestStatus: {
            errors: [],
            status: LoadingStateEnum.error
          }
        });
        return EMPTY;
      }),
      tap(({ token, ...data }) => {
        const student = this.toStudent(data);

        patchState({
          student: this.toStudent(data),
          requestStatus: {
            errors: [],
            status: LoadingStateEnum.loaded
          }
        });
        dispatch(
          new SetStudentSessionAction({
            id: student.id,
            name: student.name,
            pass: student.pass,
            token
          })
        );
      })
    );
  }

  @Action(LoadStudentByClassAndGradeAction) loadStudentByClassAndGrade(
    { patchState, dispatch }: StateContext<StudentStateModel>,
    { payload }: LoadStudentByClassAndGradeAction
  ) {
    patchState({
      requestStatus: {
        errors: [],
        status: LoadingStateEnum.loading
      }
    });
    return this.cyclesService.getStudentDataBySchoolAndGrade(payload).pipe(
      catchError(() => {
        this.toast.error(
          'O aluno não pertence à esta escola ou esta série. Por favor, tente novamente'
        );
        patchState({
          ...initialState,
          requestStatus: {
            errors: [],
            status: LoadingStateEnum.error
          }
        });
        return EMPTY;
      }),
      tap(({ token, ...data }) => {
        const student = this.toStudent(data);

        patchState({
          student: this.toStudent(data),
          requestStatus: {
            errors: [],
            status: LoadingStateEnum.loaded
          }
        });
        dispatch(
          new SetStudentSessionAction({
            id: student.id,
            name: student.name,
            pass: student.pass,
            token
          })
        );
      })
    );
  }

  // eslint-disable-next-line max-lines-per-function
  @Action(UpdateStudentBiographyAction)
  UpdateStudentBiographyAction(
    { patchState, getState, dispatch }: StateContext<StudentStateModel>,
    { payload }: UpdateStudentBiographyAction
  ) {
    const key = payload.image ? 'image' : 'biography';

    patchState({
      updateStatus: {
        key,
        errors: [],
        status: LoadingStateEnum.loading
      }
    });

    const studentBiographyPageSchema = getState().student?.pages?.find(
      ({ schema }) => schema === PageSchemaEnum.BIOGRAPHY
    );

    if (!studentBiographyPageSchema) {
      const errorMessage = 'Não foi possível encontrar a biografia do aluno.';

      this.toast.error(errorMessage);

      patchState({
        updateStatus: {
          key,
          errors: [
            {
              detail: errorMessage
            }
          ],
          status: LoadingStateEnum.error
        }
      });

      return EMPTY;
    }

    return this.cyclesService
      .updateStudentBiography(studentBiographyPageSchema.id, payload)
      .pipe(
        catchError(({ error }: LivErrorResponse) => {
          const message =
            error?.message ??
            'Não foi possível atualizar a biografia do aluno.';

          this.toast.error(message);

          patchState({
            updateStatus: {
              key,
              errors: [
                {
                  detail: message
                }
              ],
              status: LoadingStateEnum.error
            }
          });

          return EMPTY;
        }),
        tap(() => {
          patchState({
            updateStatus: {
              key,
              errors: [],
              status: LoadingStateEnum.loaded
            }
          });
          dispatch(
            new LoadStudentByTokenAction(getState().student?.pass ?? '')
          );
        })
      );
  }

  private toStudent(data: Omit<StudentModel, 'token'>): Student {
    return {
      id: data.id,
      name: data.name,
      image: data.image,
      pass: data.pass,
      teacher: data.teacher,
      schoolName: data.schoolName,
      pages: data.pages ?? []
    };
  }
}
